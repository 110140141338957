.token_img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
}

.token_container {
    justify-content: center;
}

.page-header__action .search__input {
 width: auto !important;
}

.search__input {
    background: none !important;
    padding: 12px 12px 12px 30px;
}