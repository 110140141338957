.carousel-container {
}

.carousel-img--container {
    width: 100%;
    height: 350px;
    overflow: hidden;
}

.carousel-img--container img {
    width: 100%;
}

.game-card {
    margin: 0 10px;
}

.game-card__box {
    border: white 5px solid;
}

.nft-selected {
    border: rgba(7, 0, 221, 0.697) 5px solid;
}