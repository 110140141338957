.uk-width-2-3 {
    width: 100%;
}

.widjet__body {
    display: flex;
    justify-content: space-between;
    /* align-items: flex-start; */
}

.creat-list-btn {
    width: 200px;
}

.btn-buy {
    background: rgba(102, 244, 25, 0.05);
    border: rgba(25, 244, 138, 0.35) 2px solid;
    color: #13952f;
}

.btn-sell {
    background: rgba(244, 25, 25, 0.05);
    border: rgba(244, 25, 25, 0.35) 2px solid;
    color: #951313;
}

.btn-buy:hover {
    background: #5cdb5c;
    color: #fff;
}

.btn-sell:hover {
    background: #ff0021;
    color: #fff;
}

.widjet-right {
    display: flex;
    flex-direction: column;
}

.quantity-input {
    width: 50px !important;
    color: #6E6E6E;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.43;
    height: 25px;
    margin-left: 5px;
}

.select-btn {
    font-family: 'Changa', sans-serif;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-transition: all 0.25s linear;
    -o-transition: all 0.25s linear;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 9px 25px;
    border-radius: 10px;
    background: rgba(244, 97, 25, 0.05);
    border: rgba(244, 97, 25, 0.35) 2px solid;
    color: #F65100;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.1;
    transition: all 0.25s linear;
}

.input-btn {
    font-family: 'Changa', sans-serif;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-transition: all 0.25s linear;
    -o-transition: all 0.25s linear;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 9px 25px;
    border-radius: 10px;
    background: rgba(244, 97, 25, 0.05);
    border: rgba(244, 97, 25, 0.35) 2px solid;
    color: #F65100;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.1;
    transition: all 0.25s linear;
}