.account-form-area {
    padding: 30px 20px 20px 20px;
    background-color: #fff;
    box-shadow: 0px 0px 29.4px 0.6px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    position: relative;
}

.account-form-area .close-btn {
    position: absolute;
    top: 27px;
    right: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.account-form-area .title {
    font-size: 28px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
    color: #000;
}

.account-form-area .account-form-wrapper {
    background-color: #fff;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 30px;
}

.account-form-area form .form-group {
    margin-bottom: 0;
}

.account-form-area form .form-group + .form-group {
    margin-top: 30px;
}

.account-form-area form label {
    text-transform: uppercase;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
}

.account-form-area form label sup {
    color: #f1481f;
    font-size: 100%;
    top: 0;
    right: 3px;
}

.account-form-area form input {
    padding: 0;
    padding-bottom: 10px;
    min-height: auto;
    font-size: 16px;
    color: #ffffff;
    border: none;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

.account-form-area form input::-webkit-input-placeholder {
    color: #fff;
}

.account-form-area form input::-moz-placeholder {
    color: #fff;
}

.account-form-area form input:-ms-input-placeholder {
    color: #fff;
}

.account-form-area form input:-moz-placeholder {
    color: #fff;
}

.account-form-area form .link {
    color: #c4cbf9;
    font-size: 14px;
    margin-top: 3px;
    color: #00ffb8;
}

.account-form-area form .cmn-btn {
    width: 200px;
    display: inline-block;
}

.account-form-area p {
    font-size: 16px;
}

.account-form-area p a {
    color: #31d7a9;
    font-size: 16px;
    text-transform: uppercase;
}

.account-form-area .divider {
    position: relative;
    z-index: 1;
    margin-top: 10px;
    text-align: center;
}

.account-form-area .divider::after {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 2px;
    border-radius: 50%;
    background-color: rgba(189, 189, 189, 0.502);
    z-index: -1;
}

.account-form-area .divider span {
    font-size: 16px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 600;
    padding: 5px 30px;
    background-color: #4e40b9;
}

.account-form-area .social-link-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.account-form-area .social-link-list li {
    margin: 0 10px;
}

.account-form-area .social-link-list li a {
    width: 50px;
    height: 50px;
    background-color: rgba(12, 18, 32, 0);
    border: 1px solid #655dca;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.account-form-area .social-link-list li a {
    color: #ffffff;
}

.account-form-area .social-link-list li a:hover {
    color: #ffffff;
    background-image: -moz-linear-gradient(86deg, #ec038b 0%, #fb6468 44%, #fbb936 100%);
    background-image: -webkit-linear-gradient(86deg, #ec038b 0%, #fb6468 44%, #fbb936 100%);
    background-image: -ms-linear-gradient(86deg, #ec038b 0%, #fb6468 44%, #fbb936 100%);
    box-shadow: 0px 9px 4px 0px rgba(124, 78, 25, 0.35);
}

.account-form-area .account-form-wrapper {
    background-color: #fff;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 30px;
}

.wallet-container {
    width: 100%;
}

/* button css start */
.cmn-btn {
    padding: 15px 35px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 999px;
    border: 0px !important;
    -webkit-border-radius: 999px;
    -moz-border-radius: 999px;
    -ms-border-radius: 999px;
    -o-border-radius: 999px;
    background-color: #d04b0a;
    box-shadow: 0px 17px 40px 0px rgba(124, 78, 25, 0.35);
    -webkit-transition: background-size 0.3s;
    -o-transition: background-size 0.3s;
    transition: background-size 0.3s;
    color: #ffffff;
}

.cmn-btn2 {
    padding: 15px 35px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 999px;
    border: 0px !important;
    -webkit-border-radius: 999px;
    -moz-border-radius: 999px;
    -ms-border-radius: 999px;
    -o-border-radius: 999px;
    background-color: #000;
    box-shadow: 0px 17px 40px 0px rgba(124, 78, 25, 0.35);
    -webkit-transition: background-size 0.3s;
    -o-transition: background-size 0.3s;
    transition: background-size 0.3s;
    color: #ffffff;
}

@media (max-width: 991px) {
    .cmn-btn {
        font-size: 16px;
    }
}

@media (max-width: 767px) {
    .cmn-btn {
        font-size: 14px;
    }
}

.cmn-btn:hover {
    background-size: 300%;
    color: #ffffff;
}

.cmn-btn.style--two {
    background-image: -moz-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%);
    background-image: -webkit-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%);
    background-image: -ms-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%);
    box-shadow: -1.113px 7.922px 16px 0px rgba(143, 61, 245, 0.63);
}

.cmn-btn.style--three {
    background-image: -moz-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
    background-image: -webkit-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
    background-image: -ms-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
}

.cmn-btn.btn--sm {
    padding: 10px 22px;
    font-size: 14px;
}