.widjet__body-custom {
    flex-direction: column;
    align-items: flex-start !important;
}

.user-item-select {
    border: rgba(244, 97, 25, 0.35) 2px solid;
}

.input-class {
    border-radius: 10px;
    border: rgba(244, 97, 25, 0.35) 2px solid;
}

.search__input {
    padding: 0 10px;
}